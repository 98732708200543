import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as loginActions from "store/login/actions";
import { openConfirmModal } from "components/ui/Modal/utils";
import { isAuthenticated } from "components/utils/user";
import { isNil } from "lodash";

const MODAL_SHOW_TIMEOUT = 90; // seconds before actual session expiration
const SESSION_EXPIRES_THRESHOLD = 30; // logout seconds before actual session expiration

function useSessionTimeout() {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.user);
    const isUserAuthenticated = isAuthenticated(user);

    useEffect(() => {
        const delay = user?.sessionExpires - Date.now() - MODAL_SHOW_TIMEOUT * 1000;
        if (!isUserAuthenticated || isNil(user?.sessionExpires)) {
            return;
        }

        const sessionTimeout = setTimeout(() => {
            if (Date.now() > user?.sessionExpires) {
                dispatch(loginActions.logout());
                return;
            }

            openConfirmModal({
                onConfirm: () => dispatch(loginActions.refresh()),
                onClose: () => dispatch(loginActions.logout()),
                title: "User session",
                message: <SessionTimeoutMessage onExpire={() => dispatch(loginActions.logout())} />,
                showCloseButton: false,
            });
        }, delay);

        return () => {
            clearTimeout(sessionTimeout);
        };
    }, [dispatch, isUserAuthenticated, user?.sessionExpires]);
}

const SessionTimeoutMessage: React.FC<{ onExpire(): void }> = ({ onExpire }) => {
    const sessionExpires = useSelector((state: any) => state.user?.sessionExpires);
    const [counter, setCounter] = useState(Math.round((sessionExpires - Date.now()) / 1000) - SESSION_EXPIRES_THRESHOLD);

    useEffect(() => {
        let cancellationToken = 0;

        const updateCounter = () => {
            const currentTime = Date.now();
            const remainingTime = Math.round((sessionExpires - currentTime) / 1000) - SESSION_EXPIRES_THRESHOLD;

            if (remainingTime >= 0) {
                setCounter(remainingTime);
                cancellationToken = requestAnimationFrame(updateCounter);
            } else {
                cancelAnimationFrame(cancellationToken);
                onExpire();
            }
        };

        cancellationToken = requestAnimationFrame(updateCounter);

        return () => {
            cancelAnimationFrame(cancellationToken);
        };
    }, [onExpire, sessionExpires]);

    return (
        <div className="text-center">
            <p>The session is about to expire in {counter} seconds.</p>
            <br />
            <p>Continue current session?</p>
        </div>
    );
};

export default useSessionTimeout;
