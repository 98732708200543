import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconWrap from "../../../../Icons";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import { availableGrids, documentsGridColumnKeys } from "../../../../../views/configureGrids";
import { construct, getData } from "../../../../../../store/dataGrid/actions";
import { searchAttrToFilter } from "../../../../../utils/datagrid";
import { formatJsonDateTime } from "components/utils/date";
import WaitIcon from "../../../../WaitIcon";
import ActionLabel from "../../../../Label/ActionLabel";
import { exportPdf, getValidFileName } from "components/utils/files";
import CustomList, { renderCustomFooter } from "components/ui/List/CustomList";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import DocumentsEditForm from "../DocumentsPanel/DocumentsEditForm";
import { useIsProgramLocked } from "components/views/ProgramView/utils";
import { getGridId } from "../DocumentsPanel/utils";

const CorrespondenceTab = memo(({ applicationNumber, programNumber }) => {
    const dataGridId = getGridId({ applicationNumber, type: "correspondence" });
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers();
    const [downloadingDocNumber, setDownloadingDocNumber] = useState(null);
    const isLocked = useIsProgramLocked({ programNumber });

    const dispatch = useDispatch();
    const dataGridConfig = useSelector((state) => state.dataGrid[dataGridId]);

    useEffect(() => {
        dispatch(
            construct({
                dataGridId: availableGrids.documents,
                dataGridInstanceId: dataGridId,
                filter: searchAttrToFilter(`${documentsGridColumnKeys.appId}=${applicationNumber}`),
                sort: [
                    {
                        field: documentsGridColumnKeys.date,
                        dir: "desc",
                    },
                ],
                data: true,
            })
        );
    }, [applicationNumber, dataGridId, dispatch]);

    const handleGridRefresh = useCallback(() => {
        dispatch(getData({ dataGridId }));
    }, [dataGridId, dispatch]);

    const onDownload = useCallback((item) => {
        setDownloadingDocNumber(item[documentsGridColumnKeys.documentNumber]);

        const fileName = getValidFileName({
            fileName: item[documentsGridColumnKeys.item],
            fileExtension: "pdf",
        });
        const fileContent = item[documentsGridColumnKeys.content];

        exportPdf({
            fileName,
            fileContent,
            onComplete: () => {
                setDownloadingDocNumber(null);
            },
        });
    }, []);

    const onSideBar = (dataItem) => {
        // FIX: the public buttom in `DocumentsEditForm` are not with the theme color style
        return handleOpenSidePanel(
            <DocumentsEditForm
                gridRefresh={handleGridRefresh}
                programNumber={programNumber}
                dataItem={dataItem}
                onClose={handleCloseSidePanel}
                isLocked={isLocked}
            />
        );
    };

    const actions = [
        {
            name: "view",
            icon: () => "eye-visibility-empty",
            title: "View Correspondence",
            onClick: (dataItem) => () => onSideBar(dataItem),
        },
        {
            name: "download",
            icon: (number) => (number === downloadingDocNumber ? "waiting" : "download"),
            title: "Download",
            onClick: (item) => () => onDownload(item),
        },
    ];

    if (!dataGridConfig) {
        return null;
    }

    const { rows = [], isReading, isConstructing } = dataGridConfig;

    if (isReading || isConstructing) {
        return <WaitIcon />;
    }

    const renderItem = (item) => {
        return (
            <>
                <header className="p-3 pb-0 text-xs flex justify-between">
                    <span className="opacity-[.6]">{formatJsonDateTime(item[documentsGridColumnKeys.date])}</span>
                </header>
                <div key={item[documentsGridColumnKeys.documentNumber]} className="item">
                    {item[documentsGridColumnKeys.type] === "Email" && <IconWrap iconWrapBig iconWrap="email-empty"></IconWrap>}
                    {item[documentsGridColumnKeys.type] === "Letter" && <IconWrap iconWrapBig iconWrap="mailbox-empty"></IconWrap>}
                    {item[documentsGridColumnKeys.type] === "Text Message" && <IconWrap iconWrapBig iconWrap="text-wrap"></IconWrap>}
                    <div className="item-header">
                        <ActionLabel className="item-name !line-clamp-3 break-words" onClick={() => onDownload(item)}>
                            {item[documentsGridColumnKeys.item]}
                        </ActionLabel>
                    </div>
                </div>
                <div className="item-actions px-3">
                    <div className="item-actions-wrap flex gap-2 py-4 mt-2">
                        {actions.map((action, index) => (
                            <IconWrap
                                key={`${item[documentsGridColumnKeys.documentNumber]}-${index}`}
                                icon={action.icon(item[documentsGridColumnKeys.documentNumber])}
                                title={action.title}
                                disabled={action.disabled}
                                onClick={action.onClick(item)}
                                iconWrapWhite
                                iconWrapRoundedSquare
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="sidebar-doc-tab correspondence">
            <CustomList limit={6} items={rows} renderItem={renderItem} renderFooter={renderCustomFooter} />
            {rows.length === 0 && <NothingFoundBlock nothingFoundBlockSmall icon="files" title="No correspondence" />}
        </div>
    );
});

export default CorrespondenceTab;
