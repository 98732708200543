import React, { useState, memo, useCallback, useContext } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { downloadFile, deleteFile, downloadAllFiles } from "../../../../../../store/files/actions";
import { formatJsonDateTime } from "components/utils/date";
import { refreshGrid } from "store/dataGrid/refreshGrid";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import { getGridId } from "../DocumentsPanel/utils";
import TagButton from "../../../../Button/TagButton";
import IconHandMade from "../../../../Icons/IconHandMade";
import WaitIcon from "../../../../WaitIcon";
import ActionLabel from "../../../../Label/ActionLabel";
import IconWrap from "../../../../Icons";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import CustomList, { renderCustomFooter } from "components/ui/List/CustomList";
import { useResource } from "store/resources/useResource";
import { getFileExtension, useAllowAccessCheck } from "components/utils/files";
import { openFileTab, splitWindowPositionType } from "components/utils/window";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import FileEditForm from "../../../../DataGrid/FileEditForm.js";
import Button from "components/ui/Button";
import FileView from "components/views/FileView";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import { entityType } from "components/utils/entityType";
import { useIsProgramLocked } from "components/views/ProgramView/utils";
import { defaultProjectTools } from "components/utils/dashboard";
import { setActiveDashboardTool } from "store/dashboards/actions";
import WindowContent from "components/ui/Windows/WindowContent";

export const disableDownloadText = "There are no files you are permitted to download";

const fileSecurityValues = {
    149: { icon: "add-group-filled", label: "Public" },
    150: { icon: "user-identity-person-filled", label: "Private" },
};

const FilesTab = memo(({ applicationNumber, programNumber, utilityNumber }) => {
    const dispatch = useDispatch();
    const [downloadingDocumentId, setDownloadingNumberId] = useState(null);
    const [downloadingFolderName, setDownloadingFolderName] = useState(null);
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        className: "files-grid-file-preview-sidenav-panel",
    });
    const dataGridId = getGridId({ applicationNumber, type: "applicationFiles" });

    const isLocked = useIsProgramLocked({ programNumber });
    const windowContent = useContext(WindowContent);

    const [openFolderIndices, setOpenFolderIndices] = useState([]);
    const toggleOpenFolder = (idx) => {
        const arrIdx = openFolderIndices.indexOf(idx);
        const newIndices = [...openFolderIndices];
        if (arrIdx > -1) {
            newIndices.splice(arrIdx, 1);
        } else {
            newIndices.push(idx);
        }
        setOpenFolderIndices(newIndices);
    };

    const [rows = [], isReading] = useResource({
        resourceName: "applicationFiles",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    });
    const isDownloadFolderDisabled = useAllowAccessCheck(rows);

    const onRefresh = () => {
        refreshGrid({ dataGridId });
    };

    const onDownloadFile = (item) => {
        setDownloadingNumberId(item.fileId);

        dispatch(
            downloadFile({
                fileId: item.fileId,
                onComplete: () => {
                    setDownloadingNumberId(null);
                },
            })
        );
    };

    const onEdit = (dataItem) => {
        document.activeElement.blur();

        handleOpenSidePanel(
            <FileEditForm
                gridId={dataGridId}
                dataItem={dataItem}
                utilityNumber={utilityNumber}
                onClose={handleCloseSidePanel}
                fileId={dataItem.fileId}
                fileSecurity={dataItem.fileSecurity}
                fileAuthorizationGroup={dataItem.fileAuthorizationGroupNumber || ""}
                fileAuthorizationGroupName={dataItem.fileAuthorizationGroup}
                entityTypeId={entityType.projectApplication}
                entityId={programNumber}
                tag={dataItem.tag}
                folder={dataItem.folder}
                applicationNumber={applicationNumber}
                isApplicationGrid
                isLocked={isLocked}
                onRefresh={onRefresh}
            />,
            { size: sideNavSize.small }
        );
    };

    const onDelete = (dataItem) => {
        const file = {
            fileId: dataItem.fileId,
            fileName: dataItem.fileName,
        };

        dispatch(
            deleteFile({
                ...file,
                onDeleteSuccess: () => onRefresh(),
            })
        );
    };

    const onSplit = (dataItem, isSplit) => {
        dispatch(
            setActiveDashboardTool({
                entityNumber: `${applicationNumber}-${windowContent?.splitWindowPosition ?? splitWindowPositionType.left}`,
                tool: defaultProjectTools.find((i) => i.id === "docs").panelType,
            })
        );

        openFileTab({
            fileNumber: dataItem.fileId,
            fileName: dataItem.fileName,
            openInSplitView: !!isSplit,
        });
    };

    const onOpen = useCallback(
        (dataItem) => {
            const title = dataItem.fileName;
            const leadBlockIcon = "eye-visibility-empty";

            handleOpenSidePanel(
                <SideNavContent>
                    <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={handleCloseSidePanel} />
                    <SideNavBody className="flex-one-in-column">
                        <FileView fileNumber={dataItem.fileId} />
                    </SideNavBody>
                    <SideNavFooter justifyEnd>
                        <Button onClick={handleCloseSidePanel}>Close</Button>
                    </SideNavFooter>
                </SideNavContent>
            );
        },
        [handleOpenSidePanel, handleCloseSidePanel]
    );

    const onDownloadForlder = (folder) => {
        setDownloadingFolderName(folder);

        dispatch(
            downloadAllFiles({
                entityId: applicationNumber,
                entityTypeId: entityType.projectApplication,
                folder,
                onComplete: () => {
                    setDownloadingFolderName(null);
                },
            })
        );
    };

    const actions = [
        {
            name: "open-new-window",
            icon: () => "reader-mode-empty",
            title: "Open in Split View",
            disabled: false,
            onClick: (dataItem) => () => onSplit(dataItem, true),
        },
        {
            name: "edit",
            icon: () => "edit-empty",
            title: "Edit",
            disabled: false,
            onClick: (dataItem) => () => onEdit(dataItem),
        },
        {
            name: "download",
            icon: (id) => (id === downloadingDocumentId ? "waiting" : "download"),
            title: "Download File",
            disabled: false,
            onClick: (dataItem) => () => onDownloadFile(dataItem),
        },
        {
            name: "sidebar",
            icon: () => "eye-visibility-empty",
            title: "View in Sidebar",
            disabled: false,
            onClick: (dataItem) => () => onOpen(dataItem),
        },
        {
            name: "delete",
            icon: () => "delete-trash-empty",
            title: "Delete File",
            disabled: false,
            onClick: (dataItem) => () => onDelete(dataItem),
        },
    ];
    if (isReading) {
        return <WaitIcon />;
    }

    // group rows into folders
    const folders = [];
    rows.forEach((item) => {
        let folder = folders.find((f) => f.name === item.folder);
        if (!folder) {
            folder = {
                name: item.folder,
                items: [],
                open: false,
            };
            folders.push(folder);
        }
        folder.items.push(item);
    });

    // open folders
    openFolderIndices.forEach((idx) => {
        if (!folders[idx]) return;

        folders[idx].open = true;
    });
    const renderItem = (item) => {
        return (
            <>
                <header className="p-3 pb-0 text-xs flex justify-between">
                    <span className="opacity-[.6]">{formatJsonDateTime(item.fileDate)}</span>
                    {item.tag && (
                        <div className="item-tags">
                            <TagButton className="truncate" readonly>
                                {item.tag}
                            </TagButton>
                        </div>
                    )}
                </header>
                <div key={item.fileId} className="item">
                    <IconHandMade iconDocumentHandMade title={getFileExtension(item.fileName).substring(0, 4)}></IconHandMade>
                    {item.allowAccess ? (
                        <ActionLabel className="item-name !line-clamp-2 break-words" onClick={() => onDownloadFile(item)}>
                            {item.fileName}
                        </ActionLabel>
                    ) : (
                        <span title="You do not have access to this file">{item.fileName}</span>
                    )}
                    <div className="security-type">
                        <span>File Type:</span>
                        {item.fileType}
                    </div>
                    <div className="security-type">
                        <span>File Security:</span>
                        {item.fileAuthorizationGroup || "All Access"}
                        {<IconWrap title={fileSecurityValues[item.fileSecurity].label} icon={fileSecurityValues[item.fileSecurity].icon} />}
                    </div>
                </div>
                <div className="item-actions px-3">
                    <div className="item-actions-wrap flex gap-2 py-4 mt-2">
                        {actions.map((action, index) => (
                            <IconWrap
                                key={index}
                                icon={action.icon(item.fileId)}
                                title={action.title}
                                disabled={action.disabled}
                                onClick={action.onClick(item)}
                                iconWrapWhite
                                iconWrapRoundedSquare
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    };
    return (
        <div className="sidebar-doc-tab files">
            {folders.map((folder, i) => (
                <div
                    key={i}
                    className={cn("file-folder", {
                        "file-folder--open": folder.open,
                    })}
                >
                    <div className="folder-name flex items-center cursor-pointer p-1" onClick={() => toggleOpenFolder(i)}>
                        <IconWrap className="mx-1" iconWrapBig icon={folder.open ? "folder-open-filled" : "folder"} /> {folder.name}
                        {downloadingFolderName === folder.name ? (
                            <WaitIcon className="ml-auto my-auto" />
                        ) : (
                            <IconWrap
                                icon="download"
                                className="ml-auto"
                                title={isDownloadFolderDisabled ? disableDownloadText : "Download Folder"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDownloadForlder(folder.name);
                                }}
                            />
                        )}
                    </div>

                    {folder.open && (
                        <div className="items">
                            <CustomList
                                limit={6}
                                items={folder.items}
                                renderItem={renderItem}
                                renderFooter={renderCustomFooter}
                            ></CustomList>
                        </div>
                    )}
                </div>
            ))}
            {rows.length === 0 && <NothingFoundBlock nothingFoundBlockSmall icon="files" title="No files" />}
        </div>
    );
});

export default FilesTab;
