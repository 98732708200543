import React, { useContext, useEffect, useCallback } from "react";
import cn from "classnames";
import { isFunction, isNil } from "lodash";
import { RowLayoutConfiguration } from "components/ui/PortalBuilder/types";
import { PortalBuilderPropertiesContext } from "components/ui/PortalBuilder/contexts";

import "./RowLayout.scss";

export const RowLayout = (props: RowLayoutElementProps) => {
    const { onClick, layout, selectedColumn, onColumnSelect, isColumnSelectable, layoutTooltip, isSelected, id } = props;
    const { lastSelectedColumn } = useContext(PortalBuilderPropertiesContext);

    const handleColumnSelect = useCallback((index: number, e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        
        onColumnSelect?.(index);
    }, [onColumnSelect]);

    useEffect(() => {
        if (!isNil(lastSelectedColumn)) {
            onColumnSelect?.(lastSelectedColumn);
        }
    }, [lastSelectedColumn, onColumnSelect]);

    if (isNil(layout) || !layout.columns) {
        return null;
    }

    return (
        <div
            onClick={onClick}
            className={cn("portal-row-layout flex-row fill-width", {
                "portal-row-layout--selectable": isFunction(onClick),
                highlighted: isSelected,
            })}
            id={id}
            title={layoutTooltip}
        >
            {layout.columns.map((column: any, index: number) => {
                const sectionName = `section-${index + 1}`;
                const isSelectable = isFunction(isColumnSelectable) ? isColumnSelectable(index) : true;
                
                return (
                    <div
                        key={index}
                        onClick={(e) => isSelectable && handleColumnSelect(index, e)}
                        title={isSelectable ? `Configure ${sectionName}` : undefined}
                        className={cn("portal-row-layout-section", {
                            "portal-row-layout-section--selectable": isSelectable,
                            "portal-row-layout-section--selected": index === selectedColumn,
                            "flex-one": column.span === 1,
                            "flex-two": column.span === 2,
                            "flex-three": column.span === 3,
                        })}
                    >
                        {sectionName}
                    </div>
                );
            })}
        </div>
    );
};

export interface RowLayoutElementProps {
    onClick?: () => void;
    layout?: RowLayoutConfiguration;
    selectedColumn?: number;
    onColumnSelect?: (index: number) => void;
    isColumnSelectable?: boolean;
    layoutTooltip?: string;
    isSelected?: boolean;
    id?: string;
}
