import React, { memo, useContext, useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import useSidePanelHandlers from "../../utils/useSidePanelHandlers";
import { downloadFile, deleteFile, downloadAllFiles } from "../../../store/files/actions";
import { refreshGrid } from "../../../store/dataGrid/refreshGrid";
import { filesGridColumnCellContent } from "../../utils/files";
import { openFileTab, splitWindowPositionType } from "components/utils/window";
import { WindowContext } from "../Windows/Window";
import { entityType } from "components/utils/entityType";

import DataGrid from ".";
import FilesGridFolderRow from "./FilesGridFolderRow";
import Button from "../Button";

import FileView from "components/views/FileView";
import SideNavBody from "../SideNav/SideNavBody";
import SideNavFooter from "../SideNav/SideNavFooter";
import SideNavHeader from "../SideNav/SideNavHeader";
import SideNavContent from "../SideNav/SideNavContent";
import { sideNavSize } from "../../ui/SideNav/SideNavRoot";
import FileEditForm from "./FileEditForm";

import "./FilesGrid.scss";
import { setActiveDashboardTool } from "store/dashboards/actions";
import { defaultProjectTools } from "components/utils/dashboard";

const FilesGrid = memo(
    ({
        applicationNumber,
        isApplicationGrid,
        programNumber,
        gridId,
        dataGridConfig,
        gridColumnKeys,
        isLocked,
        onRefresh,
        entityId,
        entityTypeId,
        canUseSplitScreenView,
        utilityNumber,
        canEdit,
    }) => {
        const dispatch = useDispatch();
        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
            className: "files-grid-file-preview-sidenav-panel",
        });

        const { splitWindowPosition } = useContext(WindowContext) ?? {};
        const [downloadingRowId, setDownloadingRowId] = useState(-1);
        const [downloadingFolderName, setDownloadingFolderName] = useState();

        const onDownloadFile = useCallback(
            (file, id) => {
                setDownloadingRowId(id);

                dispatch(
                    downloadFile({
                        fileId: file.fileId,
                        onComplete: () => {
                            setDownloadingRowId(-1);
                        },
                    })
                );
            },
            [dispatch]
        );

        const rowActions = [
            {
                name: "open-new-window",
                icon: canUseSplitScreenView ? "reader-mode-empty" : "open-new-window",
                title: canUseSplitScreenView ? "Open in Split View" : "View in Tab",
                disabled: (row) => isApplicationGrid && !row.allowAccess,
            },
            ...(entityTypeId === entityType.projectApplication
                ? [
                      {
                          name: "edit",
                          icon: "edit-empty",
                          title: "Edit",
                          disabled: (row) => (isApplicationGrid && !row.allowAccess) || !canEdit,
                      },
                  ]
                : []),
            {
                name: "download",
                icon: (row) => (row?.id === downloadingRowId ? "waiting" : "download"),
                title: "Download File",
                disabled: (row) => isApplicationGrid && !row.allowAccess,
            },
            {
                name: "sidebar",
                icon: "eye-visibility-empty",
                title: "View in Sidebar",
                disabled: (row) => isApplicationGrid && !row.allowAccess,
            },
            {
                name: "delete",
                icon: "delete-trash-empty",
                title: "Delete File",
                hide: isLocked,
                disabled: (row) => (isApplicationGrid && !row.allowDelete) || !canEdit,
            },
        ];

        const handleOpen = useCallback(
            ({ dataItem }) => {
                const title = dataItem[gridColumnKeys.fileName];
                const leadBlockIcon = "eye-visibility-empty";

                handleOpenSidePanel(
                    <SideNavContent>
                        <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={handleCloseSidePanel} />
                        <SideNavBody className="flex-one-in-column">
                            <FileView fileNumber={dataItem[gridColumnKeys.fileId]} />
                        </SideNavBody>
                        <SideNavFooter justifyEnd>
                            <Button onClick={handleCloseSidePanel}>Close</Button>
                        </SideNavFooter>
                    </SideNavContent>
                );
            },
            [gridColumnKeys.fileId, gridColumnKeys.fileName, handleOpenSidePanel, handleCloseSidePanel]
        );

        const onRowAction = (action) => {
            const { dataItem } = action;

            const file = {
                fileId: dataItem[gridColumnKeys.fileId],
                fileName: dataItem[gridColumnKeys.fileName],
            };

            switch (action.name) {
                case "edit":
                    document.activeElement.blur();
                    handleOpenSidePanel(
                        <FileEditForm
                            dataItem={dataItem}
                            gridId={gridId}
                            utilityNumber={utilityNumber}
                            onClose={handleCloseSidePanel}
                            fileId={dataItem[gridColumnKeys.fileId]}
                            fileSecurity={dataItem[gridColumnKeys.security]}
                            fileAuthorizationGroup={dataItem.fileAuthorizationGroupNumber || ""}
                            fileAuthorizationGroupName={dataItem[gridColumnKeys.fileAuthorizationGroup]}
                            tag={dataItem[gridColumnKeys.tag]}
                            folder={dataItem[gridColumnKeys.folder]}
                            entityTypeId={entityTypeId}
                            entityId={programNumber}
                            onRefresh={() => {
                                refreshGrid({ dataGridId: gridId });
                                onRefresh && onRefresh();
                            }}
                            applicationNumber={applicationNumber}
                            isApplicationGrid={isApplicationGrid}
                        />,
                        { size: sideNavSize.small }
                    );
                    break;
                case "download":
                    document.activeElement.blur();
                    onDownloadFile(file, dataItem.id);
                    break;
                case "open-new-window":
                    if (isApplicationGrid && canUseSplitScreenView) {
                        dispatch(
                            setActiveDashboardTool({
                                entityNumber: `${applicationNumber}-${splitWindowPosition ?? splitWindowPositionType.left}`,
                                tool: defaultProjectTools.find((i) => i.id === "docs").panelType,
                            })
                        );
                    }

                    openFileTab({
                        fileNumber: dataItem[gridColumnKeys.fileId],
                        fileName: dataItem[gridColumnKeys.fileName],
                        openInSplitView: canUseSplitScreenView ? true : false,
                        splitWindowPosition,
                    });
                    break;
                case "sidebar":
                    handleOpen({ dataItem });
                    break;
                case "delete":
                    dispatch(
                        deleteFile({
                            ...file,
                            onDeleteSuccess: () => {
                                refreshGrid({ dataGridId: gridId });
                                onRefresh && onRefresh();
                            },
                        })
                    );
                    break;
                default:
                    break;
            }
        };

        const onFolderDownload = (groupName) => {
            let folder = groupName;
            setDownloadingFolderName(groupName);
            dispatch(
                downloadAllFiles({
                    entityId,
                    entityTypeId,
                    folder,
                    onComplete: () => {
                        setDownloadingFolderName();
                    },
                })
            );
        };

        return (
            <DataGrid
                name={gridId}
                config={dataGridConfig}
                rowGroups={[
                    {
                        key: gridColumnKeys.folder,
                    },
                ]}
                groupRender={(props) => (
                    <FilesGridFolderRow {...props} onFolderDownload={onFolderDownload} downloadingFolderName={downloadingFolderName} />
                )}
                onRowAction={onRowAction}
                customRowActions={rowActions}
                columnCellContent={filesGridColumnCellContent({ gridColumnKeys })}
                noFilter
            />
        );
    }
);

export default FilesGrid;
