import TabList, { Tab } from "components/ui/List/TabList";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import WaitIcon from "components/ui/WaitIcon";
import { uniq } from "lodash";
import React, { useState } from "react";
import { useResource } from "store/resources/useResource";

import "./styles.scss";

const ReleaseNotes = () => {
    const removeQuestionMarks = (content: string): string => {
        return content.replace(/[?]/g, '');
    };

    const [releases, isLoading] = useResource({
        resourceName: "allReleaseNotes",
        key: "allReleaseNotes",
        forced: true
    }) as [releases: ReleaseResponse[] | undefined, isLoading: boolean];

    const thisYear = new Date().getFullYear().toString();
    const [activeTab, setActiveTab] = useState({ id: thisYear, title: thisYear });

    const hasReleases = releases && releases.length > 0;
    const years: Tab[] = [];

    if (!isLoading && hasReleases) {
        const releaseYears = releases.map(release => 
            new Date(release.releaseDate).getFullYear().toString()
        );
        uniq(releaseYears).sort((a, b) => Number(b) - Number(a)).forEach(year => {
            years.push({
                id: year,
                title: year,
            });
        });
    }

    const onSelectTab = (tab: Tab) => {
        setActiveTab(tab);
    };

    if (isLoading) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    if (!hasReleases) {
        return (
            <ViewPlaceholder>
                <p>Release notes not found</p>
            </ViewPlaceholder>
        );
    }

    return (
        <div className="release-notes main-grid-wrap responsive">
            <TabList loading={isLoading} items={years} onClick={onSelectTab} activeItem={activeTab}></TabList>
            {releases
                .filter(release => 
                    new Date(release.releaseDate).getFullYear().toString() === activeTab.id
                )
                .sort((a, b) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime())
                .map((release, index) => (
                    <div key={index} className="release-notes-item">
                        <div dangerouslySetInnerHTML={{ __html: removeQuestionMarks(release.value) }} />
                    </div>
                ))}
        </div>
    );
};

type ReleaseResponse = {
    id: number;
    title: string;
    value: string;
    releaseDate: string;
};

export default ReleaseNotes;
