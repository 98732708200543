import React, { useCallback, useContext, useMemo } from "react";
import Button from "../../../Button";
import { FilePreview } from "../../../PortalBuilder/Properties/Property/FileSelectProperty";
import CloseCircle from "components/ui/Icons/CloseCircle";
import TextInput from "components/ui/Input/TextInput";
import { ImageSizeProperty } from "components/ui/PortalBuilder/Properties/Property/ImageProperty/ImageSizeProperty";
import { ApplicationFormPagesContext } from "components/views/ApplicationFormPages";
import { isString, isEmpty, isNaN } from "lodash";
import { useDispatch } from "react-redux";
import { modalOpen } from "store/modal/actions";

import { LinkType } from "components/ui/PortalBuilder/types";
import { PropertyListItem } from "components/ui/PortalBuilder/Properties/Property/PropertyListItem";
import { PropertyListItemTitle } from "components/ui/PortalBuilder/Properties/PropertyList/PropertyListItemTitle";
import { useProgramPortalConfiguration } from "components/ui/PortalBuilder/utils/useProgramPortalConfiguration";
import { SelectBoxProperty } from "components/ui/PortalBuilder/Properties/Property/SelectBoxProperty";
import DropDownInput from "components/ui/Input/DropDownInput";
import { isInternalLink } from "components/ui/PortalBuilder/Properties/Settings/ProgramSettings";

import "./ImagePropertiesWidget.scss";

const ImagePropertiesWidget = (props) => {
    const { onChange } = props;
    const dispatch = useDispatch();
    const { utilityNumber, programNumber } = useContext(ApplicationFormPagesContext);

    const imagePropertiesValue = useMemo(() => {
        if (!props.value) return {};
        return isString(props.value) ? JSON.parse(props.value) : props.value;
    }, [props.value]);

    const [configData = {}, isLoadingConfigData] = useProgramPortalConfiguration({
        programNumber,
        forced: true,
    });

    const portalPages = useMemo(() => {
        if (isLoadingConfigData) return [];
        const pages = configData?.programTemplateConfiguration?.content?.pages || [];
        return pages.map(p => ({
            label: p.link,
            value: p.link,
        }));
    }, [isLoadingConfigData, configData]);

    const imageSizeProperties = useMemo(() => {
        const customSize = Number(imagePropertiesValue.imageSize);
        if (!isNaN(customSize)) {
            return { imageSize: "custom", customSize, src: true };
        }
        return { 
            imageSize: imagePropertiesValue.imageSize || "auto", 
            customSize: "100", 
            src: true 
        };
    }, [imagePropertiesValue]);

    const imageLinkProperties = useMemo(() => {
        return {
            imageLink: imagePropertiesValue?.imageLink,
            linkEnabled: !isEmpty(imagePropertiesValue?.imageLink) || imagePropertiesValue.linkEnabled,
            imageLinkType: isInternalLink(imagePropertiesValue?.imageLink) ? LinkType.INTERNAL : LinkType.EXTERNAL,
            selectExistingPages:
                imagePropertiesValue?.selectExistingPages ?? portalPages?.some((p) => p.value === imagePropertiesValue?.imageLink),
            openInNewTab: imagePropertiesValue?.openInNewTab,
            src: !isEmpty(imagePropertiesValue?.imageSrc),
        };
    }, [imagePropertiesValue, portalPages]);

    const onSelectImage = useCallback(() => {
        dispatch(
            modalOpen({
                modalType: "IMAGE_MODAL",
                modalProps: {
                    overlayClassName: "modal-styled",
                    className: "modal-lg",
                    footerContentCenter: true,
                    utilityNumber,
                    onImageChange: (file) => {
                        const newProperties = { 
                            ...imagePropertiesValue, 
                            imageSrc: file.publicPath 
                        };
                        onChange(JSON.stringify(newProperties));
                    },
                },
            })
        );
    }, [imagePropertiesValue, onChange, utilityNumber, dispatch]);

    const onRemoveImage = () => {
        onChange(JSON.stringify({ ...imagePropertiesValue, imageSrc: "" }));
    };

    const onImageSizeChange = useCallback(
        (id, value) => {
            onChange(JSON.stringify({ ...imagePropertiesValue, imageSize: value }));
        },
        [imagePropertiesValue, onChange]
    );

    const onImageLinkChange = useCallback(
        (id, value) => {
            const actions = {
                linkEnabled: () =>
                    onChange(
                        JSON.stringify({
                            ...imagePropertiesValue,
                            linkEnabled: value,
                            imageLinkType: undefined,
                            imageLink: undefined,
                            selectExistingPages: undefined,
                            openInNewTab: undefined,
                        })
                    ),
                imageLinkType: () =>
                    onChange(
                        JSON.stringify({
                            ...imagePropertiesValue,
                            imageLinkType: value,
                            imageLink: value === LinkType.INTERNAL ? "/" : " ",
                        })
                    ),
                imageLink: () => onChange(JSON.stringify({ ...imagePropertiesValue, imageLink: value })),
                selectExistingPages: () => onChange(JSON.stringify({ ...imagePropertiesValue, selectExistingPages: value })),
                openInNewTab: () => onChange(JSON.stringify({ ...imagePropertiesValue, openInNewTab: value })),
            };

            const action = actions[id];

            if (action) {
                action();
            }
        },
        [imagePropertiesValue, onChange]
    );

    const onAltTextChange = useCallback(
        (event) => {
            onChange(JSON.stringify({ ...imagePropertiesValue, imageAltText: event.target.value }));
        },
        [imagePropertiesValue, onChange]
    );

    return (
        <div className="image-properties-wrapper">
            {isEmpty(imagePropertiesValue.imageSrc) && <Button btnText="SELECT IMAGE OR UPLOAD NEW" onClick={onSelectImage}></Button>}
            {imagePropertiesValue?.imageSrc && (
                <div className="image-properties-section">
                    <div>
                        <div className="selected-file-label">Selected Image:</div>
                        <div className="selected-file-tile flex-row align-center">
                            <FilePreview
                                file={{
                                    fileName: imagePropertiesValue.imageSrc.split("/").pop(),
                                    publicPath: imagePropertiesValue.imageSrc,
                                }}
                            />
                            <span className="file-name">{imagePropertiesValue.imageSrc.split("/").pop()}</span>
                            <CloseCircle title={"Remove selected Image"} onClick={() => onRemoveImage()} />
                        </div>
                    </div>
                    <ImageSizeProperty title={"Image size:"} onChange={onImageSizeChange} value={imageSizeProperties} />
                    <ImageLinkProperty portalPages={portalPages} onChange={onImageLinkChange} value={imageLinkProperties} />
                    <div>
                        <div className="flex-row with-info-icon alt-info-wrapper">
                            <div className="text-label required">
                                Image alt text<span className="title-postfix">:</span>
                            </div>
                            <div
                                className="icon-wrap with-icon info-empty icon-wrap-medium"
                                title="Alt text is the written copy that appears in place of an image on a webpage if the image fails to load on a user's screen"
                            ></div>
                        </div>
                        <TextInput
                            error={!isEmpty(props.rawErrors)}
                            value={imagePropertiesValue.imageAltText || ""}
                            onChange={onAltTextChange}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const ImageLinkProperty = ({ portalPages, value, onChange }) => {
    const isExpanded = value.linkEnabled;

    const handlePropertyChange = useCallback(
        (property, newValue) => {
            onChange(property, newValue);
        },
        [onChange]
    );

    const onChangeLink = (event) => {
        handlePropertyChange("imageLink", event.target.value);
    };

    const isInternalLink = value.imageLinkType === LinkType.INTERNAL;

    const linkTypeItems = [
        {
            text: "Internal",
            value: "internal",
        },
        {
            text: "External",
            value: "external",
        },
    ];

    return (
        <PropertyListItem className="property-list-item--image-link">
            <PropertyListItemTitle
                title="Image url"
                toggleTooltip={!isExpanded ? "Switch ON" : "Switch OFF"}
                onToggle={() => handlePropertyChange("linkEnabled", !isExpanded)}
                toggleValue={!isExpanded}
            />
            {isExpanded && (
                <>
                    <SelectBoxProperty id="imageLinkType" onChange={onChange} items={linkTypeItems} value={value.imageLinkType} />
                    {isInternalLink ? (
                        <>
                            <PropertyListItemTitle
                                title="Select from existing pages"
                                toggleTooltip={!value.selectExistingPages ? "Switch ON" : "Switch OFF"}
                                onToggle={() => handlePropertyChange("selectExistingPages", !value.selectExistingPages)}
                                toggleValue={!value.selectExistingPages}
                            />
                            {value.selectExistingPages ? (
                                <DropDownInput
                                    id="imageLink"
                                    placeholder="Select url"
                                    data={portalPages}
                                    value={value.imageLink}
                                    onChange={onChangeLink}
                                    withPopper
                                />
                            ) : (
                                <TextInput id="imageLink" value={value.imageLink || ""} onChange={onChangeLink} />
                            )}
                        </>
                    ) : (
                        <>
                            <TextInput id="imageLink" value={value.imageLink || ""} onChange={onChangeLink} />
                            <PropertyListItemTitle
                                title="Open in the new Tab"
                                toggleTooltip={!value.openInNewTab ? "Switch ON" : "Switch OFF"}
                                onToggle={() => handlePropertyChange("openInNewTab", !value.openInNewTab)}
                                toggleValue={!value.openInNewTab}
                            />
                        </>
                    )}
                </>
            )}
        </PropertyListItem>
    );
};

export default ImagePropertiesWidget;
