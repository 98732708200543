import React, { useState, useEffect, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { getPage, savePage, setActivePage, updatePageOrder } from "../../../store/pages/actions";
import Header from "./Header";
import ApplicationFormPageEditor from "./ApplicationFormPageEditor";
import { useProgramFormPages, useProgramFormFriendlyNames, useProgramForms } from "../../../store/resources/useResource";
import { setValidationErrors, selectElement } from "store/formBuilder/actions";
import { createPage } from "store/formBuilder/utils";
import ViewPlaceholder from "../../ui/ViewPlaceholder";
import WaitIcon from "../../ui/WaitIcon";
import {
    refreshProgramCalculationsForm,
    refreshApplicationFormsInOpenTabs,
    refreshProgramFormFriendlyNames,
} from "../../../store/resources/refreshResource";
import { refreshFriendlyNamesGrid } from "../../../store/dataGrid/refreshGrid";

import "./style.scss";

export const ApplicationFormPagesContext = React.createContext({});

const ApplicationFormPages = memo(({ utilityNumber, programNumber, formNumber, pageNumber: initialPageNumber, selectedElementId }) => {
    const dispatch = useDispatch();
    const activePage = useSelector((state) => get(state, `pages[active-${programNumber}]`, null));

    const [activeTool, setActiveTool] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [hideDisabledPages, setHideDisabledPages] = useState(false);
    const [pages, isLoadingPages] = useProgramFormPages({
        programNumber,
        formNumber,
    });
    const [friendlyNames, isLoadingFriendlyNames] = useProgramFormFriendlyNames({ programNumber });
    const [form] = useProgramForms({ formNumber });
    const isNewPage = Array.isArray(pages) && activePage ? !pages.some((page) => page.number === activePage.number) : false;

    const onSave = useCallback(
        (formData) => {
            setIsSubmitting(true);

            dispatch(
                savePage({
                    isNew: isNewPage,
                    programNumber: programNumber,
                    formNumber: formNumber,
                    schema: formData.schema,
                    uiSchema: formData.uiSchema,
                    rules: formData.rules,
                    initialValues: formData.initialValues,
                    onSuccess: (page) => {
                        dispatch(setActivePage({ id: programNumber, page }));
                        refreshProgramCalculationsForm({ programNumber });
                        refreshFriendlyNamesGrid({ formNumber });
                        refreshProgramFormFriendlyNames({ programNumber });
                        refreshApplicationFormsInOpenTabs();
                    },
                    onError: (action) => {
                        dispatch(
                            setValidationErrors({
                                instanceId: activeTool?.id,
                                errorList: action.data?.errorList ?? [],
                            })
                        );
                    },
                    onComplete: () => setIsSubmitting(false),
                })
            );
        },
        [dispatch, isNewPage, programNumber, formNumber, activeTool?.id]
    );

    const createNewPage = useCallback(() => {
        const page = createPage();

        setActiveTool({ type: "page", id: page.number, data: { ...page, isNewPage: true } });
        dispatch(setActivePage({ id: programNumber, page, isNewPage: true }));
    }, [programNumber, dispatch]);

    const onToolClick = useCallback(
        (tool) => {
            switch (tool.type) {
                case "new-page":
                    createNewPage();
                    break;
                case "page":
                    if (tool.data?.isNewPage) {
                        dispatch(
                            setActivePage({
                                id: programNumber,
                                page: tool.data,
                            })
                        );
                        setActiveTool(tool);
                    } else {
                        setIsLoadingPage(true);
                        const pageNumber = tool.id;

                        dispatch(
                            getPage({
                                pageNumber,
                                programNumber,
                                formNumber,
                                onSuccess: (page) => {
                                    dispatch(setActivePage({ id: programNumber, page }));
                                    setActiveTool({
                                        ...tool,
                                        data: page
                                    });
                                    setIsLoadingPage(false);

                                    if (tool.selectedElementId) {
                                        const elementId = tool.selectedElementId.replace("root", pageNumber);
                                        // Try to select element on next cycle after page is initialized
                                        setTimeout(() => {
                                            dispatch(
                                                selectElement({
                                                    instanceId: pageNumber,
                                                    elementId,
                                                })
                                            );
                                        }, 0);
                                    }
                                },
                            })
                        );
                    }
                    break;
                default:
                    setActiveTool(tool);
            }
        },
        [dispatch, programNumber, formNumber, createNewPage]
    );

    const onToolDragEnd = useCallback(
        (result) => {
            if (!result.destination || result.destination.index === result.source.index) {
                return;
            }

            const pageOrder = result.destination.index;
            const pageNumber = result.draggableId;

            dispatch(
                updatePageOrder({
                    programNumber,
                    formNumber,
                    pageNumber,
                    pageOrder,
                })
            );
        },
        [programNumber, formNumber, dispatch]
    );
    useEffect(() => {
        if (!activePage && initialPageNumber && !isLoadingPages) {
            onToolClick({
                type: "page",
                id: initialPageNumber,
                selectedElementId,
            });
        }
    }, [activePage, initialPageNumber, selectedElementId, isLoadingPages, onToolClick]);

    useEffect(() => {
        if (activePage) {
            setActiveTool((prevActiveTool) => {
                if (activePage.number === prevActiveTool?.id) {
                    return prevActiveTool;
                }

                return {
                    type: "page",
                    id: activePage.number,
                    data: activePage
                };
            });
        }
    }, [activePage]);

    return (
        <ApplicationFormPagesContext.Provider
            value={{
                utilityNumber,
                programNumber,
                formNumber,
                friendlyNames,
                pageNumber: activePage?.number,
            }}
        >
            <div className="app-form-pages flex-column fill-height no-scroll">
                <Header
                    programNumber={programNumber}
                    utilityNumber={utilityNumber}
                    pages={pages || []}
                    activeTool={activeTool}
                    loading={isLoadingPages}
                    onToolClick={onToolClick}
                    onToolDragEnd={onToolDragEnd}
                    formName={form?.formName}
                    hideDisabledPages={hideDisabledPages}
                />
                {activePage ? (
                    <ApplicationFormPageEditor
                        key={activePage.number}
                        page={activePage}
                        onSave={onSave}
                        isSubmitting={isSubmitting}
                        isNewPage={isNewPage}
                        keepInitializedPage
                        onHideDisabledPages={() => setHideDisabledPages(!hideDisabledPages)}
                        hideDisabledPages={hideDisabledPages}
                    />
                ) : (
                    <div className="flex-column flex-one align-center justify-center">
                        {isLoadingPage || isLoadingPages || isLoadingFriendlyNames ? (
                            <WaitIcon centered />
                        ) : (
                            <ViewPlaceholder clickableText="Create New Page" clickableTextIcon="plus" onClick={createNewPage}>
                                Select Page or Create New
                            </ViewPlaceholder>
                        )}
                    </div>
                )}
            </div>
        </ApplicationFormPagesContext.Provider>
    );
});

export default ApplicationFormPages;
