import React, { memo, useCallback } from "react";
import { PropertyListItem } from "../PropertyListItem";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { SelectBoxProperty } from "../SelectBoxProperty";
import { InputRangeProperty } from "../InputRangeProperty";

import "./ImageProperty.scss";

export const ImageSizeProperty = memo((props) => {
    const { title, value = {}, onChange } = props;

    const handleChange = useCallback((id, newValue) => {
        onChange?.(id, newValue);
    }, [onChange]);

    const imageSizes = [
        {
            text: "Auto",
            value: "auto",
            tooltip: "Image is displayed in its original size",
        },
        {
            text: "Custom",
            value: "custom",
            tooltip: "Image size can be zoomed in and out",
        },
        {
            text: "Fill width",
            value: "fill-width",
            tooltip: "Image is stretched or increased to fill Section width",
        },
        {
            text: "Fill height",
            value: "fill-height",
            tooltip: "Image is stretched or increased to fill Section height",
        },
    ];

    const imageAlignment = [
        {
            text: "Left",
            value: "start",
        },
        {
            text: "Center",
            value: "center",
        },
        {
            text: "Right",
            value: "end",
        },
    ];

    if (!value?.src) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--image-size" borderBottom={false}>
            <PropertyListItemTitle title={title} />
            <SelectBoxProperty
                defaultValue={"fill-height"}
                id={"imageSize"}
                onChange={handleChange}
                items={imageSizes}
                fullWidth={true}
                value={value.imageSize || "auto"}
            />
            {value.imageSize === "custom" && (
                <InputRangeProperty 
                    id={"customSize"} 
                    onChange={handleChange} 
                    value={value.customSize || "100"} 
                    minValue={10} 
                />
            )}
            <SelectBoxProperty
                title={"Image alignment"}
                defaultValue={"start"}
                id={"imageAlignment"}
                onChange={handleChange}
                items={imageAlignment}
                value={value.imageAlignment || "start"}
            />
        </PropertyListItem>
    );
});
